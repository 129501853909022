import web3 from "./web3";

const address = "0xD765e18E1a3C858C1e946936c55795E171540F7F";
const abi = [{
    "inputs": [],
    "name": "getUserFiles",
    "outputs": [{
        "components": [{"internalType": "string", "name": "name", "type": "string"}, {
            "internalType": "string",
            "name": "hash",
            "type": "string"
        }, {"internalType": "uint256", "name": "size", "type": "uint256"}],
        "internalType": "struct FileStorageIpfs.File[]",
        "name": "",
        "type": "tuple[]"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "string", "name": "_name", "type": "string"}, {
        "internalType": "string",
        "name": "_hash",
        "type": "string"
    }, {"internalType": "uint256", "name": "_size", "type": "uint256"}],
    "name": "uploadFile",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}];
export default new web3.eth.Contract(abi, address);
