import { all } from "redux-saga/effects";
import storageSaga from "./sagas/storageSaga";

function* rootSaga() {
  yield all([
    storageSaga(),
    // Add more sagas here if needed
  ]);
}

export default rootSaga;
