import React, { useState, useRef } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons

const PinInput = ({onSubmit}) => {
    const [pin, setPin] = useState(["", "", "", ""]);
    const [showPin, setShowPin] = useState(false); // To toggle between show/hide
    const inputRefs = useRef([]);

    const handleChange = (value, index) => {
        if (isNaN(value)) return; // Only allow numbers
        const newPin = [...pin];
        newPin[index] = value;
        setPin(newPin);

        // Move to the next input field if a digit is entered
        if (value !== "" && index < pin.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        // Move back if 'Backspace' is pressed
        if (e.key === "Backspace" && pin[index] === "" && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleToggleShow = () => {
        setShowPin(!showPin); // Toggle show/hide
    };

    const handleSubmit = () => {
        onSubmit(pin.join(''));
    };

    return (
        <div>
            <h3>Enter your 4-digit PIN</h3>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                {pin.map((digit, index) => (
                    <input
                        key={index}
                        type={showPin ? 'text' : 'password'} // Toggle input type
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                        style={{ width: '40px', textAlign: 'center', fontSize: '24px' }}
                    />
                ))}

                <button onClick={handleToggleShow} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                    {showPin ? <FaEyeSlash size={24} /> : <FaEye size={24} />}
                </button>
            </div>

            <button onClick={handleSubmit} style={{ marginTop: '10px' }}>
                Submit
            </button>
        </div>
    );
};

export default PinInput;
