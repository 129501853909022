import React from "react";
import FileUpload from "./fileUpload";
import FileList from "./fileList";
import {
    ASK_PIN,
    FETCH_FILES,
    FINISH_DOWNLOAD, FINISH_UPLOAD, RESET_PIN_POPUP,
    SET_DOWNLOAD_PROGRESS,
    SET_SELECTED_FILE, SET_UPLOAD_PROGRESS,
    START_DOWNLOAD, START_UPLOAD
} from "./actions/actionTypes";
import {connect} from "react-redux";
import PinInput from "./pinInput";

const App = ({
                 files,
                 fetchFiles,
                 loading,
                 downloadProgress,
                 setDownloadProgress,
                 startDownload,
                 finishDownload,
                 setSelectedFile,
                 selectedFile,
                 reloadFiles,
                 uploadProgress,
                 setUploadProgress,
                 startUpload,
                 finishUpload,
                 askPin,
                 resetPinPopup,
                 showPin,
                 onPinSubmitFunction,
             }) => {


    return (
        <div className="App">
            <FileUpload
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                uploadProgress={uploadProgress}
                setUploadProgress={setUploadProgress}
                startUpload={startUpload}
                finishUpload={finishUpload}
                askPin={askPin}
                resetPinPopup={resetPinPopup}
            />
            <FileList
                key={reloadFiles}
                files={files}
                fetchFiles={fetchFiles}
                downloadProgress={downloadProgress}
                setDownloadProgress={setDownloadProgress}
                startDownload={startDownload}
                finishDownload={finishDownload}
                askPin={askPin}
                resetPinPopup={resetPinPopup}
            />

            {/* PIN Input Popup */}
            {showPin && (
                <div style={popupOverlayStyle}>
                    <div style={popupStyle}>
                        <PinInput onSubmit={onPinSubmitFunction} />
                    </div>
                </div>
            )}

        </div>
    );
};

const mapStateToProps = (state) => ({
    files: state.storage.files,
    downloadProgress: state.storage.downloadProgress,
    loading: state.storage.loading,
    selectedFile: state.storage.selectedFile,
    reloadFiles: state.storage.reloadFiles,
    uploadProgress: state.storage.uploadProgress,
    showPin: state.storage.showPin,
    onPinSubmitFunction: state.storage.onPinSubmitFunction,
});

const mapDispatchToProps = (dispatch) => ({
    fetchFiles: () => dispatch({type: FETCH_FILES}),
    setDownloadProgress: (progress) => dispatch({type: SET_DOWNLOAD_PROGRESS, payload: progress}),
    startDownload: () => dispatch({type: START_DOWNLOAD}),
    finishDownload: () => dispatch({type: FINISH_DOWNLOAD}),
    setSelectedFile: (file) => dispatch({type: SET_SELECTED_FILE, payload: file}),
    setUploadProgress: (progress) => dispatch({type: SET_UPLOAD_PROGRESS, payload: progress}),
    startUpload: () => dispatch({type: START_UPLOAD}),
    finishUpload: () => dispatch({type: FINISH_UPLOAD}),
    askPin: (func) => dispatch({type: ASK_PIN, payload: func}),
    resetPinPopup: () => dispatch({type: RESET_PIN_POPUP}),
});

const popupOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const popupStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
};

// Connect the component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(App);