import {
  ASK_PIN,
  FINISH_DOWNLOAD,
  FINISH_UPLOAD,
  SET_DOWNLOAD_PROGRESS,
  SET_FILES,
  SET_SELECTED_FILE,
  RESET_PIN_POPUP,
  SET_UPLOAD_PROGRESS,
  START_DOWNLOAD,
  START_UPLOAD
} from "../actions/actionTypes";

const initialState = {
  files: [],
  downloadProgress: 0,
  loading: false,
  selectedFile: null,
  uploadProgress : 0,
  reloadFiles: false,
  showPin: false,
  onPinSubmitFunction: undefined,
};

const storageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILES:
      return {
        ...state,
        files: action.payload,
        loading: false
      };
    case SET_DOWNLOAD_PROGRESS:
      return {
        ...state,
        downloadProgress: action.payload
      };
    case START_DOWNLOAD:
      return {
        ...state,
        loading: true,
        downloadProgress: 0,
      };
    case FINISH_DOWNLOAD:
      return {
        ...state,
        loading: false,
        downloadProgress: 0,
      };
    case START_UPLOAD:
      return {
        ...state,
        loading: true,
        uploadProgress: 0,
      };
    case FINISH_UPLOAD:
      return {
        ...state,
        loading: false,
        reloadFiles: !state.reloadFiles,
        selectedFile: null,
        uploadProgress: 0,
      };
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload
      };
    case SET_SELECTED_FILE:
      return {
        ...state,
        selectedFile: action.payload
      };
    case RESET_PIN_POPUP:
      return {
        ...state,
        showPin: false,
        onPinSubmitFunction: undefined
      };
    case ASK_PIN:
      return {
        ...state,
        showPin: true,
        onPinSubmitFunction: action.payload
      };
    default:
      return state;
  }
};

export default storageReducer;
