import {takeLatest, put, call} from "redux-saga/effects";
import {FETCH_FILES} from "../actions/actionTypes";
import web3 from "../web3";
import fileStorageContract from "../fileStorageContract";
import {setFiles} from "../actions/storageActions";
import store from "../store";

async function fetchFilesSaga() {

  try {
    const accounts = await web3.eth.getAccounts();
    const files = await fileStorageContract.methods.getUserFiles().call({
      from: accounts[0]
    });
    store.dispatch(setFiles(files));

  } catch (error) {
    console.error("Error fetching files:", error);
    // Handle error (dispatch an error action if necessary)
  }
}

function* storageSaga() {
    yield takeLatest(FETCH_FILES, fetchFilesSaga);
}

export default storageSaga;
