export const FETCH_FILES = "FETCH_FILES";
export const SET_FILES = "SET_FILES";
export const START_DOWNLOAD = "START_DOWNLOAD";
export const SET_DOWNLOAD_PROGRESS = "SET_DOWNLOAD_PROGRESS";
export const FINISH_DOWNLOAD = "FINISH_DOWNLOAD";
export const START_UPLOAD = "START_UPLOAD";
export const FINISH_UPLOAD = "FINISH_UPLOAD";
export const SET_SELECTED_FILE = "SET_SELECTED_FILE";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const RESET_PIN_POPUP = "RESET_PIN_POPUP";
export const ASK_PIN = "ASK_PIN";
