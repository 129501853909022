import {
  ASK_PIN,
  FETCH_FILES,
  FINISH_DOWNLOAD, FINISH_UPLOAD,
  SET_DOWNLOAD_PROGRESS,
  SET_FILES, SET_SELECTED_FILE, RESET_PIN_POPUP, SET_UPLOAD_PROGRESS,
  START_DOWNLOAD,
  START_UPLOAD
} from "./actionTypes";

export const fetchFiles = () => ({
  type: FETCH_FILES,
});

export const setFiles = (files) => ({
  type: SET_FILES,
  payload: files,
});

export const setDownloadProgress = (progress) => ({
  type: SET_DOWNLOAD_PROGRESS,
  payload: progress,
});

export const startDownload = () => ({
  type: START_DOWNLOAD,
});

export const finishDownload = () => ({
  type: FINISH_DOWNLOAD,
});


export const startUpload = () => ({
  type: START_UPLOAD,
});

export const finishUpload  = () => ({
  type: FINISH_UPLOAD,
});

export const setSelectedFile = (file) => ({
  type: SET_SELECTED_FILE,
  payload: file,
});

export const setUploadProgress = (progress) => ({
  type: SET_UPLOAD_PROGRESS,
  payload: progress,
});


export const resetPinPopup = () => ({
  type: RESET_PIN_POPUP,
});

export const askPin = (func) => ({
  type: ASK_PIN,
  payload: func,
});


